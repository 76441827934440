import apiClient from './api';


export async function fetchUserInfo() {
  try {
    const response = await apiClient.get('/user'); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

export async function fetchUsers() {
  try {
    const response = await apiClient.get('/fetch-users'); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }};

  export async function getAllUnreadNotifications() {
    try {
      const response = await apiClient.post('/get-unread-notifications'); // Replace with your endpoint
      return response;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return error.response;
      } else if (error.request) {
        // The request was made but no response was received
        return { success: false, message: "No response received", details: error.request };
      } else {
        // Something happened in setting up the request that triggered an Error
        return { success: false, message: "Request setup error", details: error.message };
      }
    }};

  export async function changeUser(userId) {
    try {
      if (localStorage.getItem('main_auth_token') == null) {
        localStorage.setItem('main_auth_token', sessionStorage.getItem('api_token'));
      }
      const response = await apiClient.post('/change-user', {
        prev_token: localStorage.getItem('main_auth_token'),
        user_id: userId
      });
      return response;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        return { success: false, message: "No response received", details: error.request };
      } else {
        // Something happened in setting up the request that triggered an Error
        return { success: false, message: "Request setup error", details: error.message };
      }
    }
};
