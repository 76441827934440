import { reactive, computed } from 'vue';

const state = reactive({
  isLoading: false,
  timeoutId: null
});

const setLoading = (loading) => {
  if (loading) {
    // If there's already a timeout, clear it
    if (state.timeoutId) {
      clearTimeout(state.timeoutId);
    }

    // Set a new timeout to delay the loading state
    state.timeoutId = setTimeout(() => {
      state.isLoading = true;
      state.timeoutId = null; // Reset timeoutId after execution
    }, 300);
  } else {
    // If loading is false, clear the timeout and immediately set isLoading to false
    if (state.timeoutId) {
      clearTimeout(state.timeoutId);
      state.timeoutId = null;
    }
    state.isLoading = false;
  }
};

export default {
  isLoading: computed(() => state.isLoading),
  setLoading
};
