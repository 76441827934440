<template>
  <div id="mytask-layout" class="theme-indigo">

    <div class="main" style="display: flex;">
        <!-- Body: Body -->
            <slot />
      </div>
    </div>
</template>

<script>
export default {
  name: 'LoginLayout'
};
</script>

<style></style>
