<template>
    <div class="body d-flex">
        <div class="container-xxl">
            <div class="body d-flex">
                <div class="container-xxl">
                    <div class="row">
                        <h1 class="fw-bold page-title d-none">Αλλαγή κωδικού πρόσβασης</h1>
                    </div>
                </div>
                <br>
                <div class="deadline-form" id="update-user-form">
                    <form>
                        <div class="row justify-content-center">
                            <div class="col-sm-4 shadow p-3 rounded">
                                <label for="userOldPassword" class="form-label">Παλιός Κωδικός</label>
                                <input type="password" class="form-control mb-4" id="userOldPassword" v-model="oldPassword" placeholder="********">
                                <label for="userEditPassword" class="form-label">Νέος Κωδικός</label>
                                <input type="password" class="form-control mb-4" id="userEditPassword" v-model="newPassword" placeholder="********">
                                <label for="userEditPasswordConfirm" class="form-label">Επιβεβαίωση νέου κωδικού</label>
                                <input type="password" class="form-control" id="userEditPasswordConfirm" v-model="newPasswordConfirm" placeholder="********">
                                <div class="d-flex justify-content-center pt-4">
                                    <button type="button" class="btn btn-primary updateUser w-100" @click="submitForm">Ενημέρωση κωδικού</button>
                                </div>
                            </div>
                        </div>
                        <div id="update-user-errors"></div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { changePassword } from '@/services/change-password';

export default {
    name: 'ChangePassword',
    setup() {
        const toast = useToast();
        const oldPassword = ref('');
        const newPassword = ref('');
        const newPasswordConfirm = ref('');

        const submitForm = async () => {
            try {
                const response = await changePassword({
                    old_password: oldPassword.value,
                    password: newPassword.value,
                    password_confirmation: newPasswordConfirm.value
                });
                if (response.data.success) {
                    toast.success(response.data.message);
                    // Refresh the page
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('Error submitting form');
            }
        };

        return { oldPassword, newPassword, newPasswordConfirm, submitForm };
    },
    metaInfo: {
        title: 'Aλλαγή Password',
        meta: [
            { name: 'description', content: 'Marketplace' },
            { property: 'og:title', content: 'Marketplace' },
            { property: 'og:description', content: 'Marketplace.' }
        ]
    }
};
</script>

<style scoped>
/* Add your styles here */
</style>
