<template>
  <div class="row clearfix g-3">
    <div style="display: flex; flex: 1; align-items: flex-end; flex-direction: column;">
    </div>
    <div class="col-xl-8 col-lg-12 col-md-12 flex-column">
    </div>
    <div class="col-xl-4 col-lg-12 col-md-12">
    </div>
  </div>
</template>

<script>
import router from '@/router';
import ApexCharts from 'apexcharts';
import { useToast } from 'vue-toastification';

export default {
  data() {},
  computed: {},
  methods: {},
  mounted() {
  }
};
</script>

<style scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
