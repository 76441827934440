import { createRouter, createWebHistory } from 'vue-router';
import Login from './views/Login.vue';
import Register from './views/Register.vue';
import Dashboard from './views/Dashboard.vue';
import ResetPassword from './views/ResetPassword.vue';
import { fetchUserInfo } from './services/user';
import ChangePassword from './views/ChangePassword.vue';
import BusinessPartners from './views/BusinessPartners.vue';
import BusinessPartnerRequest from './views/BusinessPartnerRequest.vue';
import AllBusinessPartnerRequests from './views/AllBusinessPartnerRequests.vue';
import BusinessPartnerProfile from './views/BusinessPartnerProfile.vue';
import Offers from './views/Offers.vue';
import ShowBusinessPartnerProfile from './views/ShowBusinessPartnerProfile.vue';
import OfferProfile from './views/OfferProfile.vue';
import Home from './views/Home.vue';
import MainHome from './views/MainHome.vue';
import Category from './views/Category.vue';
import Profile from './views/Profile.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { layout: 'MainFrontLayout' }
  },
  {
    path: '/main-home',
    name: 'Main Home',
    component: MainHome,
    meta: { layout: 'unset' }
  },
  {
    path: '/category/:id/:cat_name',
    name: 'Category',
    component: Category,
    meta: { layout: 'MainFrontLayout' }
  },
  {
    path: '/profile/:category_id/:id',
    name: 'Profile',
    component: Profile,
    meta: { layout: 'MainFrontLayout' }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { layout: 'MainLayout' }
  },
  {
    path: '/merchant',
    name: 'Login',
    component: Login,
    meta: { layout: 'LoginLayout' }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      layout: 'NoLayout' // No layout for Register component
    },
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: ResetPassword,
    meta: { layout: 'LoginLayout' }
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/business-partner-request',
    name: 'BusinessPartnerRequest',
    component: BusinessPartnerRequest,
    meta: {
      layout: 'LoginLayout'
    }
  },
  {
    path: '/all-business-partner-requests',
    name: 'AllBusinessPartnerRequests',
    component: AllBusinessPartnerRequests,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/business-partner-profile',
    name: 'BusinessPartnerProfile',
    component: BusinessPartnerProfile,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/business-partners',
    name: 'BusinessPartners',
    component: BusinessPartners,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/offers',
    name: 'Offers',
    component: Offers,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/show-business-partner-profile',
    name: 'ShowBusinessPartnerProfile',
    component: ShowBusinessPartnerProfile,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/offer-profile',
    name: 'OfferProfile',
    component: OfferProfile,
    meta: {
      layout: 'MainLayout'
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach( async (to, from, next) => {
    // Meta tags population
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    if (nearestWithMeta) {
      nearestWithMeta.meta.metaTags.forEach(tag => {
        const element = document.createElement('meta');
        Object.keys(tag).forEach(key => {
          element.setAttribute(key, tag[key]);
        });
        document.head.appendChild(element);
      });
    }

    // Auth guard
    const publicPages = ['Home', 'Category', 'Login', 'Reset Password', 'BusinessPartnerRequest', 'Profile', 'Main Home'];
    const authRequired = !publicPages.includes(to.name) && !to.path.startsWith('/category/') && !to.path.startsWith('/profile/');
    const token = localStorage.getItem('api_token');

    if (to.path == '/register') {
      try {
        const userInfo = await fetchUserInfo();
        if (!userInfo || userInfo.data.info == null || !userInfo.data.info.superUser) { throw new Error('Invalid/expired token'); }
        next();
      } catch (error) {
        next({ name: 'Home' });
      }
    }

    if (authRequired) {
      try {
        const userInfo = await fetchUserInfo();
        if (!userInfo || userInfo.data.info == null) { throw new Error('Invalid/expired token'); }
        next();
      } catch (error) {
        localStorage.removeItem('api_token');
        next({ name: 'Login' });
      }
    } else {
      next();
      // if (token) {
      //   try {
      //     const userInfo = await fetchUserInfo();
      //     if (!userInfo || userInfo.data.info == null) { throw new Error('Invalid/expired token'); }
      //     if (to.name === 'Login') {
      //       next({ name: 'Dashboard' });
      //     } else {
      //       next();
      //     }
      //   } catch (error) {
      //     localStorage.removeItem('api_token');
      //     if (to.name !== 'Login') {
      //       next({ name: 'Login' });
      //     }
      //   }
      // } else {
      //   next();
      // }
    }
  });

export default router;
