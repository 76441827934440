import apiClient from './api';

async function changePassword(data) {
  try {
    const response = await apiClient.post('/change-password', data);
    return response;
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
}

export { changePassword };
