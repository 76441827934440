<template>
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <h1 class="page-title  d-none">Λίστα Αιτήσεων</h1>
            <div class="row clearfix g-3">
                <div class="col-lg-12">
                    <div class="mb-3">
                        <div class="card-body table-responsive">
                            <table id="clients" class="table table-hover align-middle mb-0 mis_table"
                                style="width:100%">
                                <thead>
                                    <tr>
                                        <th style="width:20%;">
                                            <div>ΕΠΩΝΥΜΙΑ</div>
                                        </th>
                                        <th style="width:15%;">
                                            <div>ΟΝΟΜΑΤΕΠΩΝΥΜΟ</div>
                                        </th>
                                        <th style="width:20%;">
                                            <div>EMAIL</div>
                                        </th>
                                        <th style="width:20%;">
                                            <div>ΑΦΜ</div>
                                        </th>
                                        <th style="width:15%;">
                                            <div>ΑΠΟΔΟΧΗ/ΑΠΟΡΡΙΨΗ</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(businessPartnersData, index) in data" :key="data.id">
                                        <td>
                                            {{ businessPartnersData.trademark }}
                                        </td>
                                        <td>
                                            {{ businessPartnersData.Contactperson }}
                                        </td>
                                        <td>
                                            {{ businessPartnersData.Email }}
                                        </td>
                                        <td>
                                            {{ businessPartnersData.Vatcode }}
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-center gap-2">
                                                <button @click="acceptAction(businessPartnersData)" class="accept-button-outer">
                                                    <div class="accept-button">
                                                        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M173.898 439.404l-166.4-166.4c-12.496-12.497-12.496-32.758 0-45.255l28.284-28.284c12.497-12.497 32.758-12.497 45.255 0L192 312.69l238.863-238.863c12.497-12.497 32.758-12.497 45.255 0l28.284 28.284c12.497 12.497 12.497 32.758 0 45.255l-312.45 312.451c-12.497 12.496-32.758 12.496-45.254-.001z" />
                                                        </svg>
                                                    </div>
                                                    <div class="accept-text">Αποδοχή</div>
                                                </button>
                                                <button @click="rejectAction(businessPartnersData)" class="decline-button-outer">
                                                    <div class="decline-button">
                                                        <svg viewBox="0 0 352 512" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M242.7 256l100.1-100.1c12.3-12.3 12.3-32.2 0-44.5l-22.6-22.6c-12.3-12.3-32.2-12.3-44.5 0L176 188.7 75.9 88.6c-12.3-12.3-32.2-12.3-44.5 0L8.8 111.2c-12.3 12.3-12.3 32.2 0 44.5L108.9 256 8.8 356.1c-12.3 12.3-12.3 32.2 0 44.5l22.6 22.6c12.3 12.3 32.2 12.3 44.5 0L176 323.3l100.1 100.1c12.3 12.3 32.2 12.3 44.5 0l22.6-22.6c12.3-12.3 12.3-32.2 0-44.5L242.7 256z" />
                                                        </svg>
                                                    </div>
                                                    <div class="decline-text">Απόρριψη</div>
                                                </button>
                                                <!-- <button @click="acceptAction(businessPartnersData)" class="btn btn-success mb-2">Αποδοχή</button>
                                                <button @click="rejectAction(businessPartnersData)" class="btn btn-danger">Απόρριψη</button> -->
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="col" v-if="!data || !data.length">
                                        <td>
                                            No data
                                        </td>
                                        <td>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <nav aria-label="Page navigation">
                                <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                                    <li class="page-item" v-for="(paginationLink, index) in paginationLinks"
                                        :key="index" :class="{ active: paginationLink.active }">
                                        <router-link
                                            :to="{ path: 'all-business-partner-requests', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }"
                                            v-if="paginationLink.url">
                                            <span class="page-link" v-html="paginationLink.label"></span>
                                        </router-link>
                                        <span class="page-link" v-else v-html="paginationLink.label"></span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col" v-if="!data || !data.length">
                <p>Δεν βρεθηκαν αποτελέσματα...</p>
            </div> -->
        </div>
    </div>
</template>

    
    <script>
    import { ref, onMounted, onUnmounted } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useFetchData } from '@/composables/useFetchData';
    import { fetchAll, acceptPartner, rejectPartner } from '@/services/all-business-partner-requests';
    import 'vue-toastification/dist/index.css';
import { useToast } from 'vue-toastification';
    
    export default {
        name: 'AllBusinessPartnerRequests',
        setup() {
            const route = useRoute();
            const router = useRouter();
    
            const { data, paginationLinks, filters, updateFilters, fetchData, sortBy } = useFetchData(fetchAll, { page: route.query.page || 1 }, { page: 1 });
    
            const getPageFromUrl = (url) => {
                if (!url) return null;
                const urlObj = new URL(url, window.location.origin);
                return urlObj.searchParams.get('page');
            };
    
            const searchAction = () => {
                setTimeout(() => {
                    router.push({ path: '/all-business-partner-requests', query: { ...route.query, search: $("#main-search-input").val() } });
                }, 300);
            };
    
            onMounted(() => {
                $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
            });
    
            onUnmounted(() => {
                $(document.body).off('keyup', "#main-search-input", searchAction).off('click', "#main-search-input", searchAction);
            });
    
            const acceptAction = async (businessPartnersData) => {
                // Logic to handle acceptance
                const r = await acceptPartner({id: businessPartnersData.id});
                // Implement your accept logic here
                if (r.data.success) {
                    useToast().success('Επιτυχής αποδοχή χρήστη!');
                    fetchData();
                } else {
                    useToast().error('Προέκυψε κάποιο σφάλμα.');
                    
                }
            };
    
            const rejectAction = async (businessPartnersData) => {
                // Logic to handle rejection
                const r = await rejectPartner({id: businessPartnersData.id});
                // Implement your accept logic here
                if (r.data.success) {
                    useToast().success('Επιτυχής απόρριψη χρήστη!');
                    fetchData();

                } else {
                    useToast().error('Προέκυψε κάποιο σφάλμα.');
                }
            };
    
            return { data, paginationLinks, filters, updateFilters, fetchData, getPageFromUrl, sortBy, acceptAction, rejectAction };
        },
        metaInfo: {
            title: 'Λίστα αιτημάτων',
            meta: [
                { name: 'description', content: 'Λίστα αιτημάτων' },
                { property: 'og:title', content: 'Λίστα αιτημάτων' },
                { property: 'og:description', content: 'Λίστα αιτημάτων' }
            ]
        }
    };
    </script>    

<style scoped>
    .accept-button-outer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 25px;
        height: 25px;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition-duration: .3s;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
        background-color: #428AB7;
    }
    .accept-button {
        width: 100%;
        transition-duration: .3s;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .accept-button svg {
        width: 15px;
    }
    .accept-button svg path {
        fill: white;
    }
    .accept-text {
        position: absolute;
        right: 0%;
        width: 0%;
        opacity: 0;
        color: white;
        font-size: 1em;
        font-weight: 600;
        transition-duration: .3s;
    }
    .accept-button-outer:hover {
        width: 100px;
        border-radius: 40px;
        transition-duration: .3s;
    }
    .accept-button-outer:hover .accept-button {
        width: 30%;
        transition-duration: .3s;
        padding-left: 0px;
    }
    .accept-button-outer:hover .accept-text {
        opacity: 1;
        width: 75%;
        transition-duration: .3s;
        padding-right: 10px;
    }
    .accept-button-outer:active {
        transform: translate(2px, 2px);
    }


    .decline-button-outer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 25px;
        height: 25px;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition-duration: .3s;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
        background-color: #777;
    }
    .decline-button {
        width: 100%;
        transition-duration: .3s;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .decline-button svg {
        width: 15px;
    }
    .decline-button svg path {
        fill: white;
    }
    .decline-text {
        position: absolute;
        right: 0%;
        width: 0%;
        opacity: 0;
        color: white;
        font-size: 1em;
        font-weight: 600;
        transition-duration: .3s;
    }
    .decline-button-outer:hover {
        width: 100px;
        border-radius: 40px;
        transition-duration: .3s;
    }
    .decline-button-outer:hover .decline-button {
        width: 24%;
        transition-duration: .3s;
        padding-left: 0px;
    }
    .decline-button-outer:hover .decline-text {
        opacity: 1;
        width: 75%;
        transition-duration: .3s;
        padding-right: 10px;
    }
    .decline-button-outer:active {
        transform: translate(2px, 2px);
    }
</style>