export default {
    beforeMount(el, binding) {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = binding.value;
      el.innerHTML = textArea.value;
    },
    updated(el, binding) {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = binding.value;
      el.innerHTML = textArea.value;
    }
  };