<template>
    <div class="container-fluid px-0">
        <div class="px-xl-5 px-3 pt-0 pt-xl-3 topSection">
            <div class="logoEpimelitirio centered-mobile"><img src="../assets/images/home/logo-epimelitirio.png" alt="" width="220"></div>
            <div class="d-flex flex-column-mobile text-white align-items-center py-xl-5 py-3">
                <div class="col-12 col-xl-8 ps-3">
                    <h1>
                        Το <b>MARKETPLACE</b> είναι <br>
                        <span style="background: linear-gradient(0deg, rgba(45,156,224,1) 25%, rgba(255,255,255,0) 25%)"><b><i>μια καινοτόμος πλατφόρμα</i></b></span><br>
                        που προσφέρεται από το <br>
                        <b>Επαγγελματικό Επιμελητήριο.</b>
                    </h1>
                </div>
                <div class="col-12 col-xl-4">
                    <div class="d-flex flex-column mt-4 mt-xl-0 gap-4 align-items-center text-center">
                        <div class="borderBoxes">
                            <h3>Προώθηση <br>επιχειρηματικότητας</h3>
                        </div>
                        <div class="borderBoxes">
                            <h3>Ενίσχυση <br>τοπικής αγοράς</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center" style="width: fit-content;margin: 0 auto;top: 50px;position: relative;">
                <img src="../assets/images/home/signup-more.png" alt="" width="150">
            </div>
        </div>
        <div class="px-xl-5 px-3"> 
            <div class="d-flex gap-xl-0 gap-3 flex-column-mobile justify-content-between">
                <div class="mt-xl-0 mt-5 d-flex align-items-center centered-mobile position-relative">
                    <img class="threelines" src="../assets/images/home/3lines.png" alt="" width="40">
                    <h3 style="color: #0A4A72;">
                        <span class="fs-3"><b>ΟΙ ΔΗΜΟΙ</b></span><br> ΠΟΥ ΣΥΜΜΕΤΕΧΟΥΝ
                    </h3>
                    <img class="arrow" src="../assets/images/home/arrow.png" alt="" width="100">
                </div>
                <div class="position-relative centered-mobile col-10 col-xl-2">
                    <input 
                        ref="searchInput"
                        class="form-control search-input" 
                        type="search" 
                        placeholder="Αναζήτηση..." 
                        aria-label="Αναζήτηση"
                    >
                    <img 
                        class="search-icon" 
                        src="../assets/images/home/searchIcon.png" 
                        alt="Search Icon"
                    >
                </div>
            </div>
            <div class="carouselContainer py-3">
                <!--:autoplay="{
                    delay: 10000,
                    disableOnInteraction: false,
                    }"-->
                <swiper
                    :slidesPerView="5"
                    :spaceBetween="50"
                    :autoplay="{
                    delay: 3000,
                    disableOnInteraction: false,
                    }"
                    :pagination="{
                    clickable: true,
                    }"
                    :navigation="true"
                    :modules="modules"
                    :loop="true"
                    :breakpoints="{
                        240: { // Mobile view (up to 640px wide)
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                        1024: { // Tablet and larger screens (1024px and above)
                            slidesPerView: 5,
                            spaceBetween: 50,
                        }
                    }"
                    class="mySwiper"

                >
                    <swiper-slide>
                        <div class="dimoiBox shadow rounded">
                            <img src="../assets/images/home/dimos-neas-ionias.png" alt="">
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="dimoiBox shadow rounded">
                            <img src="../assets/images/home/dimos-neas-ionias.png" alt="">
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="dimoiBox shadow rounded">
                            <img src="../assets/images/home/dimos-neas-ionias.png" alt="">
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="dimoiBox shadow rounded">
                            <img src="../assets/images/home/dimos-neas-ionias.png" alt="">
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="dimoiBox shadow rounded">
                            <img src="../assets/images/home/dimos-neas-ionias.png" alt="">
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="dimoiBox shadow rounded">
                            <img src="../assets/images/home/dimos-neas-ionias.png" alt="">
                        </div>
                    </swiper-slide>
                    <!-- <swiper-slide>
                        <div class="dimoiBox shadow rounded">
                            <img src="../assets/images/home/dimos-neas-ionias.png" alt="">
                        </div>
                    </swiper-slide> -->
                </swiper>
            </div>
        </div>
        <div style="background: #F2F2F2;height: 125px;margin-top: -150px;"><!--Divider--></div>
        <div class="px-xl-5 px-3 pb-3 profSection"> 
            <div class="d-flex gap-3 gap-xl-0 flex-column-mobile align-items-center justify-content-around">
                <div class="mt-xl-0 mt-4 ">
                    <h3 class="fs-1 mb-3 fw-bold" style="color: #0A4A72;">
                        <i>ΕΙΣΑΙ<span style="color:#2D9CE0;position: relative;top: -5px;right: -5px;">_</span><br>ΕΠΑΓΓΕΛΜΑΤΙΑΣ;</i>
                    </h3>
                    <span class="fs-4">
                        Δήλωσε στον Δήμο σου το ενφιαφέρον, <br>
                        να προσθέσεις και εσύ την επιχείρησή<br>
                        σου στο <span class="fs-3" style="color: #0A4A72;"><b>MARKET PLACE!</b></span><br>
                    </span>
                    <button class="mt-3 myBtnHome btn">ΕΝΔΙΑΦΕΡΟΜΑΙ</button>
                </div>
                <div class="col-9 col-xl-4">
                    <img src="../assets/images/home/banner-lady.png" alt="" class="sectionBnrImg">
                </div>
            </div>
        </div>
        
        <div class="px-xl-5 px-3 pb-5"> 
            <div class="d-flex flex-column-reverse-mobile align-items-center justify-content-around">
                <div class="col-xl-5 col-12 bottomGridSection">
                    <div class="grid-container">
                        <div class="grid-item item-1 rounded">
                            
                        </div>
                        <div class="grid-item item-2 rounded">
                            
                        </div>
                        <div class="grid-item item-3 rounded">
                           
                        </div>
                        <div class="grid-item item-4 rounded">
                            
                        </div>
                    </div>
                    
                </div>
                <div>
                    <h3 class="fs-1 mb-3 fw-bold text-uppercase" style="color: #0A4A72;">
                        <i>Ψάχνεις για<span style="color:#2D9CE0;position: relative;top: -5px;right: -5px;">_</span><br>προσφορές;</i>
                    </h3>
                    <span class="fs-4">
                        Βρες γρήγορα & εύκολα καταστήματα,<br>
                        εκπτώσεις και υπηρεσίες <br>
                        στον δήμο που σε ενδιαφέρει!<br>
                    </span>
                    <button class="mt-3 myBtnHome btn text-uppercase">Βρες τα όλα εδώ</button>
                </div>
            </div>
        </div>
        <br>
        
        
    </div>
</template>

<script>
    import { useHead } from "@vueuse/head";
    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from 'swiper/vue';

    // Import Swiper styles
    import 'swiper/css';
    import 'swiper/css/pagination';
    import 'swiper/css/navigation';

    // import required modules
    import { Autoplay, Pagination, Navigation } from 'swiper/modules';

    export default {
        name: "MainHome",
        components: {
            Swiper,
            SwiperSlide,
        },
        setup() {
            return {
                modules: [Autoplay, Pagination, Navigation],
            };
        },
        mounted() {
            useHead({
                title: "Αρχική - Επιμελητήριο | Marketplace",
                meta: [
                    { name: "description", content: "Αρχική" },
                    { property: "og:title", content: "Αρχική" },
                    { property: "og:description", content: "Αρχική" },
                ],
            });
        },
    };
</script>

<style scoped>
    .topSection{
        /* background-color: #377499; */
        background-image: url("../assets/images/home/main-banner.jpg");
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .profSection{
        background-image: url("../assets/images/home/second-banner.jpg");
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .logoEpimelitirio img{
        filter: brightness(0) invert(1);
    }
    .borderBoxes {
        position: relative;
        padding: 0px 20px 0px 20px;
        border: none;
        border-width: 0px 2px 2px 2px;
        border-style: solid;
        border-color: #2D9CE0;
    }
    .borderBoxes h3{
        margin-top: -15px;
    }
    /* Create the partial top border */
    .borderBoxes::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 2px; /* thickness of the top border */
        width: 100%;
        background: linear-gradient(90deg, rgba(45,156,224,1) 0%, rgba(45,156,224,1) 10%, rgba(255,255,255,0) 10%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 90%, rgba(45,156,224,1) 90%, rgba(45,156,224,1) 100%);
    }
    .search-icon{
        position: absolute;
        right: 15px;
        top: 8px;
        transform: translateY(0%);
        cursor: pointer;
        width: 25px;
    }
    .search-input{
        border: 2px solid var(--color-300);
        border-radius: 50px;
        outline: none;
        background: #fff;
    }
    .threelines{
        position: relative;
        top: -50px;
    }
    .carouselContainer{
        /* width: 95%; */
        margin: 0 auto;
    }
    .dimoiBox{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 30px 0px;
        height: 260px;
        background: #ffffff;
    }
    .dimoiBox img{
        width: 60%;
        user-select: none;
    }
    img{
        user-select: none;
    }
    .swiper-wrapper{
        padding: 20px 0px 20px 0px !important;
    }
    .myBtnHome{
        border-radius: 99px;
        background: #2D9CE0;
        font-size: x-large;
        padding: 2px 20px;
        font-weight: bold;
        color: #fff;
        transition: 1s;
    }
    .myBtnHome:hover{
        background: #2D9CE0;
        color: #fff;
        transform: scale(1.1);
        transition: 1s;
    }
    .sectionBnrImg{
        width: 100%;
    }
    .grid-container{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 150px;
        gap: 20px;
    }
    .item-1{
        background-image: url("../assets/images/home/grid1.jpg");
        grid-column: span 3;
        grid-row: span 3;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .item-2{
        background-image: url("../assets/images/home/grid2.jpg");
        grid-column: span 1;
        grid-row: span 1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .item-3{
        background-image: url("../assets/images/home/grid3.jpg");
        grid-column: span 1;
        grid-row: span 1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .item-4{
        background-image: url("../assets/images/home/grid4.jpg");
        grid-column: span 1;
        grid-row: span 1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .bottomGridSection{
        margin-top: -70px
    }
    @media only screen and (max-width: 768px){
        .centered-mobile{
            display: flex;
            justify-content: center;
            align-items: center;
            margin:0 auto;
        }
        .topSection .flex-column-mobile{
            text-align: center;
        }
        .flex-column-mobile{
            display: flex;
            flex-direction: column;
        }
        .flex-column-reverse-mobile{
            display: flex;
            flex-direction: column-reverse;
        }
        .grid-container{
            grid-auto-rows: 100px;
            gap: 10px;
        }
        .bottomGridSection{
            margin-top: 30px;
        }
    }
</style>
