<template>
  <div id="mytask-layout" class="theme-indigo">
    <!--Menu Sidebar-->
    <div id="main-menu">
      <div class="sidebar px-4 py-4 py-md-2 me-0">
        <div class="d-flex flex-column h-100">
          <RouterLink to="/offers" v-if="userInfo && !userInfo.superUser">
            <div align="center" class="style2">
              <img style="width:130px;" v-if="userInfo && userInfo.municipality_image"
                :src="`/storage/LOGOS/${userInfo.municipality_image}`" alt="Municipality Logo" />
              <h1 class="fw-bold fs-3 color-800">Marketplace</h1>
              <h2 class="mb-0 fs-5 text-dark" v-if="userInfo">
                {{ userInfo.municipality.title }}
              </h2><br>
            </div>
          </RouterLink>
          <RouterLink to="/business-partners" v-if="userInfo && userInfo.superUser">
            <div align="center" class="style2">
              <img style="width:130px;" v-if="userInfo && userInfo.municipality_image"
                :src="`/storage/LOGOS/${userInfo.municipality_image}`" alt="Municipality Logo" />
              <span class="fw-bold fs-3 color-800">Marketplace</span><br>
              <span class="mb-0 fs-5 text-dark" v-if="userInfo">
                {{ userInfo.municipality.title }}
              </span><br>
            </div><br>
          </RouterLink>
          <div v-if="userInfo && !userInfo.superUser" class="row">

            <div class="menu-item mt-4">
              <i class="icofont-simple-right color-600"></i>
              <RouterLink to="/offers" class="menu-link" @click="toggleSidebar">
                <span>Προσφορές</span>
              </RouterLink>
            </div>

            <div class="menu-item">
              <i class="icofont-simple-right color-600"></i>
              <RouterLink to="/business-partner-profile" class="menu-link" @click="toggleSidebar">
                <span>Επεξ. Προφίλ</span>
              </RouterLink>
            </div>

            <div class="menu-item">
              <i class="icofont-simple-right color-600"></i>
              <!-- <RouterLink to="/change-password" class="menu-link">
                <span>Αλλαγή κωδικού</span>
              </RouterLink> -->
              <span class="menu-link" style="cursor: pointer;" @click="changePasswordModal">
                Αλλαγή κωδικού
              </span>
            </div>

            <div class="menu-item">
              <i class="icofont-simple-right color-600"></i>
              <span @click.prevent="logout" class="menu-link" style="cursor:pointer;">Αποσύνδεση</span>
            </div>

            <div class="mt-4 menu-item company-data">
              <i class="icofont-location-pin"></i>{{ partnerData.Address }}
            </div>
            <div class="menu-item company-data">
              <i class="icofont-phone"></i>{{ partnerData.Phone }}
            </div>
            <div class="menu-item company-data">
              <i class="icofont-phone"></i>{{ partnerData.mobile }}
            </div>

            <div class="menu-item company-data mt-4">
              <a :href="partnerData.Webpage_link" target="_blank" rel="noopener noreferrer">
                <!-- <i class="icofont-web icon-large"></i> -->
                <img src="../assets/images/internet-grey.png" alt="" width="30">
              </a>
              <a :href="partnerData.Facebook_link" target="_blank" rel="noopener noreferrer">
                <!-- <i class="icofont-facebook icon-large"></i> -->
                <img src="../assets/images/facebook-grey.png" alt="" width="30">
              </a>
              <a :href="partnerData.instagram_link" target="_blank" rel="noopener noreferrer">
                <!-- <i class="icofont-instagram icon-large"></i> -->
                <img src="../assets/images/instagram-grey.png" alt="" width="30">
              </a>
            </div>

          </div>
          <!-- Menu: main ul -->
          <ul class="menu-list flex-grow-1 mt-3">
            <li v-if="userInfo && userInfo.superUser">
              <i class="icofont-arrow-right"></i>
              <RouterLink to="/business-partners" class="menu-link">

                <span style="margin-left:2%">Λίστα επιχειρήσεων</span>
              </RouterLink>
            </li><br>
            <li v-if="userInfo && userInfo.superUser">
              <i class="icofont-arrow-right"></i>
              <RouterLink to="/all-business-partner-requests" class="menu-link">

                <span style="margin-left:2%">Αιτήσεις εγγραφής</span>
              </RouterLink>
            </li><br>
            <li v-if="userInfo && userInfo.superUser">
              <i class="icofont-arrow-right"></i>
              <RouterLink to="/change-password" class="menu-link">

                <span style="margin-left:2%">Αλλαγή κωδικού</span>
              </RouterLink>
            </li><br>
            <li v-if="userInfo && userInfo.superUser">
              <i class="icofont-arrow-right"></i>
              <span @click.prevent="logout" class="menu-link"
                style="cursor:pointer;margin-left:2%;font-size: larger;">Αποσύνδεση</span>
            </li>
            
            
          </ul>

        </div>
      </div>
    </div>
    <!-- End of Menu Sidebar -->

    <!-- main body area -->
    <div class="main px-lg-4 px-md-2">
      <!--Navigation bar-->
      <div id="main-header">
        <nav class="navbar pt-xl-4 pt-2 pb-0">
          <div class="container-xxl">
            <!-- header rightbar icon -->
            <div class="h-right d-flex align-items-center mr-5 mr-lg-0 order-1 zindex-popover" style="z-index: 3;">
              <div class="d-flex"></div>
              <div class="dropdown user-profile ml-2 ml-sm-3 d-flex align-items-center ">
                <div class="u-info me-2">
                  <!-- <p class="mb-0 text-end line-height-sm"><span class="font-weight-bold user-name" v-if="userInfo">{{ userInfo.name }}</span></p>
                  <small class="user-email" v-if="userInfo">{{ userInfo.email }}</small> -->
                </div>
                <a @click.prevent="toggleMenu()" class="nav-link dropdown-toggle user-menu-toggler pulse p-0" href="#"
                  role="button" data-bs-toggle="dropdown" data-bs-display="static">
                  <div class="col">
                    <div class="icon-container">
                      <!-- <i class="icofont-user rounded-circle"></i> -->
                      <img src="../assets/images/profile.png" alt="" width="50">
                    </div>
                  </div>
                </a>
                <div
                  :class="{ 'dropdown-menu user-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0': !isMenuOpen, 'dropdown-menu user-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0 show': isMenuOpen }"
                  :style="menuStyle">
                  <div class="card border-0 w280">
                    <div class="card-body pb-0">
                      <div class="d-flex py-1">
                        <div class="flex-fill ms-3">
                          <p class="mb-0"><span class="font-weight-bold user-name" v-if="userInfo">{{ userInfo.name
                              }}</span></p>
                          <span class="user-email fw-bold" v-if="userInfo">{{ userInfo.email }}</span>
                        </div>
                      </div>
                      <div>
                        <hr class="dropdown-divider border-dark">
                      </div>
                    </div>
                    <div class="list-group m-2">
                      <div>
                        <div class="container-xxl users-container mb-2 mt-2" v-if="showUsersContainer">
                          <div class="col-md-12">
                            <label class="form-label" for="user">Αλλαγή πελάτη</label>
                            <select class="form-select" name="user" id="user" aria-label="Πελάτης"
                              aria-placeholder="Πελάτης" placeholder="Πελάτης" v-model="selectedUser"
                              @change="changeUserHandler(selectedUser)">
                              <option value="" selected>Παρακαλώ επιλέξτε</option>
                              <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }} {{
                                user.surname }}</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <a class="list-group-item list-group-item-action border-0" style="cursor: pointer"
                        @click="changePasswordModal">
                        <i class="icofont-lock fs-6 me-2"></i>Αλλαγή κωδικού
                      </a>

                      <a @click.prevent="logout" href="#"
                        class="list-group-item list-group-item-action border-0 btn-logout">
                        <i class="icofont-logout fs-6 me-2"></i>Αποσύνδεση
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <!-- menu toggler -->
            <button class="navbar-toggler p-0 border-0 menu-toggle order-3" type="button" @click="toggleSidebar">
              <span class="fa fa-bars"></span>
            </button>

            <div class="d-flex align-items-center gap-3 order-3 order-sm-0 w-sm-100 flex-column-mobile">
              <div>
                <h1 class="fw-bold color-700 fs-3">{{ pageTitle }}</h1>
              </div>
              <!-- main menu Search-->
              <div class="mb-3 mb-md-0">
                <div class="input-group flex-nowrap input-group-lg" v-if="showSearch">
                  <input id="main-search-input" type="search" class="form-control rounded" placeholder="Αναζήτηση..."
                    aria-label="search" aria-describedby="addon-wrapping">
                  <div class="searchIcon color-700">
                    <i class="fa fa-search"></i>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </nav>
      </div>

      <div class="modal fade" id="changePasswordModalOpen" tabindex="-1" aria-labelledby="" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <form @submit.prevent="submitChangePassword">
                <div class="modal-header d-flex justify-content-center ">
                  <h4>Αλλαγή κωδικού πρόσβασης</h4>
                </div>
                <div class="row justify-content-center">
                  <!-- <div class="p-3 rounded">
                          <label for="userOldPassword" class="form-label">Παλιός Κωδικός</label>
                          <input type="password" class="form-control mb-4" id="userOldPassword" v-model="oldPassword"
                            placeholder="********">
                            <span class="showPasswordIcon" @click="showPass()"><i class="icofont-eye-alt"></i></span>
                          <label for="userEditPassword" class="form-label">Νέος Κωδικός</label>
                          <input type="password" class="form-control mb-4" id="userEditPassword" v-model="newPassword"
                            placeholder="********">
                          <label for="userEditPasswordConfirm" class="form-label">Επιβεβαίωση νέου κωδικού</label>
                          <input type="password" class="form-control" id="userEditPasswordConfirm" v-model="newPasswordConfirm"
                            placeholder="********">
                          <div class="d-flex justify-content-center pt-4">
                            <button type="submit" class="btn btn-primary updateUser w-100">Ενημέρωση κωδικού</button>
                          </div>
                        </div> -->
                  <div class="p-3 rounded">
                    <!-- Old Password Field -->
                    <label for="userOldPassword" class="form-label">Παλιός Κωδικός</label>
                    <div class="mb-4 position-relative">
                      <input :type="showOldPassword ? 'text' : 'password'" class="form-control" id="userOldPassword"
                        v-model="oldPassword" placeholder="********">
                      <button class="btn position-absolute top-0 end-0 fs-6 color-700" type="button" @click="togglePasswordVisibility('old')">
                        <i :class="showOldPassword ? 'icofont-eye-blocked' : 'icofont-eye-alt'"></i>
                      </button>
                    </div>

                    <!-- New Password Field -->
                    <label for="userEditPassword" class="form-label">Νέος Κωδικός</label>
                    <div class="position-relative mb-4">
                      <input :type="showNewPassword ? 'text' : 'password'" class="form-control" id="userEditPassword"
                        v-model="newPassword" placeholder="********">
                      <button class="btn position-absolute top-0 end-0 fs-6 color-700" type="button" @click="togglePasswordVisibility('new')">
                        <i :class="showNewPassword ? 'icofont-eye-blocked' : 'icofont-eye-alt'"></i>
                      </button>
                    </div>

                    <!-- Confirm New Password Field -->
                    <label for="userEditPasswordConfirm" class="form-label">Επιβεβαίωση νέου κωδικού</label>
                    <div class="position-relative">
                      <input :type="showConfirmPassword ? 'text' : 'password'" class="form-control"
                        id="userEditPasswordConfirm" v-model="newPasswordConfirm" placeholder="********">
                      <button class="btn position-absolute top-0 end-0 fs-6 color-700" type="button"
                        @click="togglePasswordVisibility('confirm')">
                        <i :class="showConfirmPassword ? 'icofont-eye-blocked' : 'icofont-eye-alt'"></i>
                      </button>
                    </div>

                    <!-- Submit Button -->
                    <div class="d-flex justify-content-center pt-4">
                      <button type="submit" class="btn btn-primary updateUser w-100">Ενημέρωση κωδικού</button>
                    </div>
                  </div>
                </div>
                <div id="update-user-errors"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!--end of Navigation bar-->

      <!-- Main content area -->
      <div id="main-container">
        <LoadingSpinner :isLoading="isLoading" />
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch, nextTick } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { fetchUserInfo, fetchUsers, changeUser } from '../services/user'; // Adjust this import as needed
import { getCurrentBusinessPartnerData } from '@/services/business-partners';
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import { changePassword } from '@/services/change-password';


// Define a reactive variable to track whether the current mode is light or dark
const isLightMode = ref(false);
const topBarElement = document.getElementById('topbar-h1'); // Assuming you have an h1 in your top bar with this id

if (topBarElement) {
  topBarElement.innerText = document.title; // Set the h1 to the current document's title
}

export default {
  name: 'MainLayout',
  components: {
    LoadingSpinner
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    };
  },
  

  mounted() {
    this.fetchUserData();
    // Set the current year in the footer
    const yearSpan = document.getElementById('footeryear');
    if (yearSpan) {
      yearSpan.textContent = new Date().getFullYear();
    }
  },
  methods: {
    ...mapActions(['fetchUserData']),
    togglePasswordVisibility(field) {
      // Toggle the visibility for the corresponding field
      if (field === 'old') {
        this.showOldPassword = !this.showOldPassword;
      } else if (field === 'new') {
        this.showNewPassword = !this.showNewPassword;
      } else if (field === 'confirm') {
        this.showConfirmPassword = !this.showConfirmPassword;
      }
    }
  },

  setup() {
    const isLoading = ref(false);
    const userInfo = ref(null); // Use cached user info if available
    const users = ref([]); // Ref to store fetched users
    const selectedUser = ref(null); // Ref to store selected user
    const showUsersContainer = ref(true); // Ref to control visibility of users container
    const menuItemsCollapsed = ref(['dashboard']);

    const isMenuOpen = ref(false); // Reactive variable to track menu state
    const isNotificationsOpen = ref(false); // Reactive variable to track notifications state
    const router = useRouter();
    const isSidebarOpen = ref(false);
    const isSidebarMini = ref(false);
    const route = useRoute();
    const showSearch = ref(false);
    const partnerData = ref(false);
    const pageTitle = ref('');

    const toast = useToast();
    const oldPassword = ref('');
    const newPassword = ref('');
    const newPasswordConfirm = ref('');

    const changePasswordModal = () => {
      $('#changePasswordModalOpen').modal('toggle');
      $('.sidebar').removeClass('open');
    };

    const submitChangePassword = async () => {
      try {
        const response = await changePassword({
          old_password: oldPassword.value,
          password: newPassword.value,
          password_confirmation: newPasswordConfirm.value
        });
        if (response.data.success) {
          toast.success(response.data.message);
          // Refresh the page
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        toast.error('Error submitting form');
      }
    };

    watch(
      () => route.name,
      (newRoute) => {
        const hiddenRoutes = [
          "ChangePassword",
          "BusinessPartnerProfile",
          "OfferProfile",
          "ShowBusinessPartnerProfile"
        ];

        // Check if the current route name is in the list of hidden routes
        if (hiddenRoutes.includes(newRoute)) {
          showSearch.value = false; // Hide search bar
        } else {
          showSearch.value = true; // Show search bar
        }
      },
      { immediate: true }  // Ensure it runs immediately on load
    );

    const openMainMenuItem = (menuItem) => {
      const index = menuItemsCollapsed.value.indexOf(menuItem);
      if (index > -1) {
        menuItemsCollapsed.value.splice(index, 1);
      } else {
        menuItemsCollapsed.value.push(menuItem);
      }
    };

    const toggleSidebarMini = () => {
      isSidebarMini.value = !isSidebarMini.value;
      $('.sidebar').toggleClass('sidebar-mini');

      if (isSidebarMini.value) {
        $('.sidebar').removeClass('open');
      }
    };

    const toggleSidebar = () => {
      isSidebarOpen.value = !isSidebarOpen.value;

      if (isSidebarOpen.value) {
        // Sidebar is open
        $('.sidebar').addClass('open');
        if (isSidebarMini.value) {
          $('.sidebar').addClass('sidebar-mini');
        }
      } else {
        // Sidebar is close
        $('.sidebar').removeClass('open');
        $('.sidebar').removeClass('sidebar-mini');
      }
    };

    const isRtl = ref(false); // Add this line to define reactive variable for RTL mode

    // Watch for changes in RTL mode checkbox
    watch(isRtl, (newValue) => {
      if (newValue) {
        document.body.classList.add('rtl_mode');
      } else {
        document.body.classList.remove('rtl_mode');
      }
    });

    const startLoading = () => {
      isLoading.value = true;
    };

    const stopLoading = () => {
      isLoading.value = false;
    };

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value; // Toggle menu state
      isNotificationsOpen.value = false;
    };

    const handleNotificationLinkClick = (event) => {
      event.preventDefault();
      const target = $(event.target);
      const dataId = target.attr('data-id');
      const dataType = target.attr('data-type');

      if (dataId) {
        switch (dataType) {
          case 'project':
            router.push({ path: '/project', query: { id: dataId } });
            break;
          case 'task':
            router.push({ path: '/task', query: { id: dataId } });
            break;
          case 'subtask':
            router.push({ path: '/subtask', query: { id: dataId } });
            break;
          case 'host':
            router.push({ path: '/host', query: { id: dataId } });
            break;
          // Add more cases here for different types
          default:
            console.log('Unknown data-type with ID:', dataType, dataId);
        }
      } else {
        switch (dataType) {
          case 'hours-creation':
            router.push({ path: '/hours-requests', query: {} });
            break;
          case 'days-off-creation':
            router.push({ path: '/days-off-requests', query: {} });
            break;
          case 'missing-hours-days':
            router.push({ path: '/hours', query: {} });
            break;
          // Add more cases here for different types
          default:
            console.log('Unknown data-type without ID:', dataType);
        }
      }
    };

    const menuStyle = computed(() => ({
      top: isMenuOpen.value ? '100%' : 'initial',
      right: isMenuOpen.value ? '0' : 'initial'
    }));

    const notificationStyle = computed(() => ({
      top: isNotificationsOpen.value ? '100%' : 'initial',
      right: isNotificationsOpen.value ? '0' : 'initial'
    }));

    const fetchUserData = async () => {
      try {
        const response = await fetchUserInfo();
        userInfo.value = response.data?.info || null;
        if (userInfo.value && userInfo.value.customer_id === null && userInfo.value.superUser === 1) {
          showUsersContainer.value = true; // Set showUsersContainer to true if conditions are met
          fetchUsersData(); // Fetch users data if conditions are met
        } else {
          showUsersContainer.value = false; // Set showUsersContainer to false if conditions are not met
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    const getCurrentPartnerData = async () => {
      try {
        const response = await getCurrentBusinessPartnerData();
        partnerData.value = response.data.data || false;
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    const fetchUsersData = async () => {
      try {
        const response = await fetchUsers();
        showUsersContainer.value = response.data.success; // Set showUsersContainer based on response success
        if (response.data.success) {
          users.value = response.data.data; // Update users ref with response data
        } else {
          console.error("Failed to fetch users:", response.data.message);
        }
      } catch (error) {
        showUsersContainer.value = false; // Set showUsersContainer to false in case of error
        console.error("Failed to fetch users:", error);
      }
    };

    // Handle user change
    const changeUserHandler = async (userId) => {
      const userInfo = await fetchUserInfo();
      if (userInfo.data.info?.id) {
        if (userId && parseInt(userInfo.data.info?.id) != parseInt(userId)) {
          const toast = useToast();
          try {
            const response = await changeUser(userId); // Call the changeUser function with the selected userId
            if (response.data.success) {
              // Update the Authorization header with the new token
              const newToken = response.data.data.token.plainTextToken;

              localStorage.removeItem('api_token');
              localStorage.setItem('api_token', newToken);

              await fetchUserData();

              toast.success(response.data.message); // Show success message

              // Reload the page
              window.location.reload();
            } else {
              toast.error(response.data.message); // Show error message
            }
          } catch (error) {
            console.error('Error changing user:', error);
            toast.error('An error occurred while changing the user.'); // Show generic error message
          }
        }
      }
    };

    const closeMenu = (event) => {
      if (!document.querySelector('.user-menu').contains(event.target) && !document.querySelector('.user-menu-toggler').contains(event.target)) {
        isMenuOpen.value = false;
      }

    };

    const logout = (event) => {
      event.preventDefault();
      localStorage.removeItem('api_token');
      localStorage.removeItem('main_auth_token');
      router.push('/merchant');
    };

    // Function to update the pageTitle based on the current DOM element
    const updateTitle = async () => {
      await nextTick(); // Wait for the DOM to be fully updated after rendering

      const pageTitleElement = document.querySelector('.page-title'); // Get the first element with the class "page-title"
      if (pageTitleElement) {
        // Check if the element has content
        const newTitle = pageTitleElement.textContent.trim(); // Trim to remove extra spaces
        // Update the reactive pageTitle only if it's different
        if (newTitle && newTitle !== pageTitle.value) {
          pageTitle.value = newTitle; // Update the reactive value
        }
        // Retry fetching the title after a short delay if empty
        if (!newTitle) {
          setTimeout(updateTitle, 100); // Retry after 100ms
        }
      } else {
        pageTitle.value = "";
      }
    };

    // Watch for changes in route.path to update the title dynamically
    watch(
      () => route.path, // Watch for route path changes
      async () => {
        await updateTitle(); // Run the title update when the route changes

      },
      { immediate: true } // Ensure it runs immediately on load
    );

    // Update the title when the component is mounted (also handles reload)
    onMounted(async () => {
      await updateTitle(); // Make sure to update the title on page reload
    });

    onMounted(() => {
      getCurrentPartnerData();
      if (!userInfo.value) {
        fetchUserData(); // Fetch user info if not already cached
      }
      $(document.body).on('click', '.notification-link', handleNotificationLinkClick);
      document.addEventListener('click', closeMenu);

      router.beforeEach((to, from, next) => {
        startLoading();
        next();
      });
      router.afterEach(() => {
        stopLoading();
      });
    });

    onUnmounted(() => {
      $(document.body).off('click', '.notification-link', handleNotificationLinkClick);
      document.removeEventListener('click', closeMenu);
    });

    return {
      partnerData,
      showSearch,
      userInfo,
      users,
      selectedUser,
      menuStyle,
      toggleMenu,
      isMenuOpen,
      isNotificationsOpen,
      notificationStyle,
      menuItemsCollapsed,
      openMainMenuItem,
      showUsersContainer,
      changeUserHandler,
      isLightMode,
      route,
      router,
      isLoading,
      toggleSidebar,
      isSidebarOpen,
      isSidebarMini,
      isRtl,
      toggleSidebarMini,
      logout,
      pageTitle,
      changePasswordModal,
      submitChangePassword,
      oldPassword,
      newPassword,
      newPasswordConfirm,
    };
  }
};
</script>


<style scoped>
.submenu.collapsed {
  display: none;
}

.submenu.collapse {
  display: block;
}

.sidebar {
  background: #EDEDED;
  /* position: sticky;
  left: 0;
  top: 25px; */
  z-index: 2;
}

input[type="password"]::placeholder {
  font-size: 16px;
}

input[type="password"]{
  letter-spacing: 2.5px;
}

.m-link {
  width: 160px;
  padding: 5px;
  left: 15px;
  top: 10px;
  margin: 0px;
  background-color: #f6f6f6;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  text-align: center;

}

.m-link span {
  padding: 5px;

  color: #616161;
  text-align: center;
  font-size: large;
}

.arrow.icofont-dotted-up {
  transform: rotate(180deg);
}

.stStatusInfoCyanMedium {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  color: #00a0d2;
  font-size: 20px;
  font-weight: normal;
}

.stStatusInfoCyan24 {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  color: #00a0d2;
  font-size: 30px;
  font-weight: normal;
}

.col {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.icon-container {
  display: flex;
  align-items: center;
  gap: 10px;
  /* Adds space between the icons */
}

.icon-container i {
  font-size: 24px;
  /* Adjust the size of the icons */
  color: #4A90E2;
  /* Choose a color that fits your design */
  transition: color 0.3s ease;
}

.d-flex.center.align-middle {
  display: flex;
  justify-content: center;
  /* Centers horizontally */
  align-items: center;
  /* Centers vertically */
  height: 100%;
  /* Adjust this depending on the parent container */
}


.icon-container i:hover {
  color: #2C3E50;
  /* Changes the color on hover */
}

.menu-item {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  /* Adjust for spacing between items */
}

.menu-item i {
  margin-right: 5px;
  /* Adjust for space between icon and text */
  font-size: 1rem;
}

.menu-link>span,
.menu-item>span {
  font-size: larger;
}

.menu-link {
  flex-grow: 1;
  /* Ensures the text stays to the right of the icon */
}

.menu-link:hover {
  color: var(--primary-color);
}

.icon-large {
  font-size: 1.6rem;
  /* Adjust icon size */
  transition: transform 0.3s ease, color 0.3s ease;
  /* Smooth transition */
}

.icon-large:hover {
  color: var(--primary-color);
  /* Replace with your primary color */
  transform: translateY(-5px);
  /* Move up by 5px */
  margin-bottom: 5px;
}

.modal.show .modal-dialog {
  transform: translate(0%, 50%);
}

.company-data {
  color: #777777;
}
/* Responsive behavior for smaller screens */
@media (min-width: 1050px) {
  .sidebar{
    position: sticky;
    top: 25px;
    left: 0;
  }
}
@media (max-width: 768px) {
  .sidebar{
    z-index: 4;
    box-shadow: 0px 0px 15px 2px #dedede;
  }
  .modal-content{
    width: 90%;
    margin: 0 auto;
  }
  .flex-column-mobile {
      display: flex;
      flex-direction: column
  }
}
</style>