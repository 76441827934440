<template>
  
                <div style="max-width: 25rem;"> </div>
            <slot />
          
</template>

<script>
export default {
  name: 'RegisterLayout'
};
</script>