<template>
  <div class="body d-flex pb-lg-3 pb-md-2">
    <div class="container-xxl">
      <div class="row clearfix">
        <div class="col-md-12">
          <div class="card border-0 mb-2 no-bg">
            <div class="card-header my-2 pt-0 px-0 d-sm-flex align-items-center main-button-area">
              <!-- Edit button for navigating to the edit page -->
              <button class="border-0 btn btn-primary p-1 bg-green" @click="edit(formData)" style="margin-right:5px">
                <img src="../assets/images/edit.png" alt="" width="30">
              </button>
              <button class="border-0 btn btn-primary p-1 bg-danger" @click="del(formData)">
                <img src="../assets/images/trash.png" alt="" width="30">
              </button>             
            </div>
          </div>
        </div>
      </div>

      <!-- Two-column layout: Offer details on the left, Offer image on the right -->
      <div class="row clearfix g-3">
        <!-- Left Column (Details) -->
        <div class="col-lg-6">
          <!-- Offer Description -->
          <div class="row d-none">
            <div class="col-md-12">
              <h1 class="page-title mb-0 flex-fill mb-0 mt-sm-0 fw-bold">{{ formData.descr }}</h1>
            </div>
          </div>
          <div class="row mb-3">
            <div class="mb-3">
              <label class="form-label"><i class="color-700">Ημ/νια δημοσίευσης: </i><strong>{{ formData.publishdate_formatted }}</strong></label>
            </div>
            <div>
              <label class="form-label color-800 fs-6"><strong><img src="../assets/images/calendar.png" alt="" width="24"> Ημ/νια έναρξης: {{ formData.startdate_formatted }}</strong></label>
            </div>
            <div>
              <label class="form-label color-800 fs-6"><strong><img src="../assets/images/calendar.png" alt="" width="24"> Ημ/νια λήξης: {{ formData.enddate_formatted }}</strong></label>
            </div>
          </div>

          <!-- Offer Text -->
          <div class="row text-justify" style="max-width: 80%;">
            <label class="form-label fs-5"><strong class="underlined">Περιγραφή:</strong></label>
            <p>{{ formData.offertext }}</p>
          </div>

          <!-- Status (conditionally rendered) -->
          <div class="row mt-3">
            <h5 v-if="formData.status === 1" style="color: green; font-weight: bold;">ΔΗΜΟΣΙΕΥΜΕΝΗ</h5>
            <p v-else></p>
          </div>
        </div>

        <!-- Right Column (Offer Image) -->
        <div class="col-lg-6 d-flex justify-content-center align-items-center">
          <img v-if="formData.offer_img" :src="`/storage/${formData.offer_img}`" alt="Offer Image" class="img-fluid rounded offerImg"/>
          <p v-else>No Image Available</p>
        </div>
      </div>
    </div>
  </div>


  <!-- Bootstrap Modal for Creating Offer -->
  <div class="modal fade" id="createOfferModal" tabindex="-1" aria-labelledby="createOfferModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <form @submit.prevent="submitCreateOffer">
            <div class="row">
              <div class="col-md-4 mb-3">
                <label for="descr" class="form-label">Τίτλος </label>
                <input v-model="formData.descr" type="text" class="form-control" id="descr" required>
              </div>

              <div class="col-md-4 mb-3">
                <label for="startdate" class="form-label">Ημ/νια έναρξης</label>
                <input v-model="formData.startdate" type="date" class="form-control" id="startdate" required>
              </div>
              <div class="col-md-4 mb-3">
                <label for="enddate" class="form-label">Ημ/νια λήξης</label>
                <input v-model="formData.enddate" type="date" class="form-control" id="enddate" required>
              </div>

              <div class="col-md-6 mb-3">
                <label for="offer_category_ids" class="form-label">Κατηγορία προσφοράς</label>
                <select v-model="formData.offer_category_id" class="form-select" id="offer_category_id" required>
                  <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.title }}
                  </option>
                </select>
              </div>

              <div class="col-md-6 mb-3">
                <label for="offer_img" class="form-label">Φωτογραφία</label>
                <div class="d-flex align-items-center">
                  <input @change="handleFileUpload" type="file" class="form-control" id="offer_img" style="margin-left: 10px;" />
                </div>
                <div class="form-check mt-2"
                  v-if="formData.offer_img && typeof formData.offer_img.indexOf == 'function' && formData.offer_img.indexOf('http') !== -1">
                  <input v-model="formData.delete_img" class="form-check-input" type="checkbox" id="delete_img">
                  <label class="form-check-label" for="delete_img">
                    Διαγραφή εικόνας
                  </label>
                </div>
              </div>

              <div class="col-md-12 mb-3">
                <label for="offertext" class="form-label">Περιγραφή προσφοράς</label>
                <textarea v-model="formData.offertext" class="form-control" id="offertext" rows="3" required></textarea>
              </div>

              <div class="col-md-6 mb-3">
                <label for="publishdate" class="form-label">Ημ/νια δημοσίευσης</label>
                <input v-model="formData.publishdate" type="date" class="form-control" id="publishdate" required>
              </div>

              <div class="col-md-6 mb-3">
                <label for="status" class="form-label">Δημοσιευμένη</label>
                <select v-model="formData.status" class="form-select" id="status" required>
                  <option value="1">ΝΑΙ</option>
                  <option value="0">ΟΧΙ</option>
                </select>
              </div>

            </div>
            
            <div class="d-flex justify-content-end">
              <button type="submit" class="btn btn-primary">Αποθήκευση</button>
            </div>
              
            
            
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="deleteOfferModal" tabindex="-1" aria-labelledby="deleteOfferModal" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">

        <div class="modal-body">
          <form @submit.prevent="submitDeleteOffer">
            <div class="row">
              Είστε σίγουροι ότι θέλετε να διαγράψετε την προσφορά;

            </div><br>
            <button type="submit" class="align-items-end justify-content-end btn btn-danger"
              style="margin-left:80%">Διαγραφή</button>
          </form>
        </div>
      </div>
    </div>
  </div>



</template>


<script>
import { ref, onMounted } from 'vue';
import { useHead } from '@vueuse/head';
import { useToast } from 'vue-toastification';
import { useRoute, useRouter } from 'vue-router';
import { getCurrentOfferData, createOrUpdateOffer, deleteOffer, getOfferCategories, get } from '../services/offers';

export default {
  name: 'OfferProfile',

  setup() {
    const router = useRouter(); // Define router

    const formData = ref({
      descr: '',
      price: '',
      offertext: null,
      offerprice: null,
      startdate: '',
      enddate: '',
      publishdate: '',
      offer_category_id: '',
      status: '',
      offer_img: ''
    });

    const toast = useToast();
    const route = useRoute(); // Access the current route


    const fetchFormData = async () => {
      const offerId = route.query.id; // Extract the 'id' parameter from the URL
      const data = await getCurrentOfferData({ id: offerId });

      if (data.data.success) {
        formData.value = { ...formData.value, ...data.data.data };
      }
    };

    const categories = ref([]); // Example category data, replace with API call

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      formData.value.offer_img = file;  // Store the file directly in offerForm
    };

    const submitCreateOffer = async () => {
      const data = new FormData();
      // Append all fields to FormData, including the file
      data.append('id', formData.value.id);
      data.append('descr', formData.value.descr);
      data.append('price', formData.value.price);
      data.append('offertext', formData.value.offertext);
      data.append('startdate', formData.value.startdate);
      data.append('enddate', formData.value.enddate);
      data.append('publishdate', formData.value.publishdate);
      data.append('status', formData.value.status);
      data.append('offer_category_id', formData.value.offer_category_id);

      if (formData.value.offer_img && formData.value.delete_img != true) {
        // Add the file (only if one exists and not marked for deletion)
        data.append('delete_img', false);
        data.append('offer_img', formData.value.offer_img);
      } else if (formData.value.delete_img) {
        data.append('delete_img', true);
      }

      // Now, submit the form using FormData
      const response = await createOrUpdateOffer(data);

      formData.value.offer_img = null;
      formData.value.delete_img = false;

      if (response.data.success) {
        $('#createOfferModal').modal('toggle');
        $("#offer_img").val('').trigger('change');
        useToast().success(response.data.message);
        fetchFormData();
      } else {
        useToast().error(response.data.message);
      }
    };

    

    const submitDeleteOffer = async () => {

      // Now, submit the form using FormData
      const response = await deleteOffer(formData.value.id);

      if (response.data.success) {
        $('#deleteOfferModal').modal('toggle');
        useToast().success(response.data.message);
        // Redirect to the /offers page after 1 second
        setTimeout(() => {
          router.push('/offers'); // Assumes you have the Vue Router setup
        }, 1000);
      } else {
        useToast().error(response.data.message);
      }
    };

    const createNewOffer = () => {
      formData.value = {
        id: null,
        descr: '',
        price: '',
        offertext: '',
        startdate: '',
        enddate: '',
        publishdate: '',
        offer_category_id: null,
        status: 1,
        offer_img: null,
        delete_img: false  // Track if the user wants to delete the image
      };
      $('#createOfferModal').modal('toggle');
    }

    const edit = async (formData) => {
      const r = await get({ id: formData.id });
      if (r.data.success) {
        formData.value = {
          id: r.data.data.id,
          descr: r.data.data.descr,
          price: r.data.data.price,
          offertext: r.data.data.offertext,
          startdate: r.data.data.startdate,
          enddate: r.data.data.enddate,
          publishdate: r.data.data.publishdate,
          delete_img: false,
          offer_category_id: r.data.data.offer_category_id,
          status: r.data.data.status,
          offer_img: r.data.data.offer_img,
        };
      }
      $("#createOfferModal").modal('toggle');
    };

    const del = async (formData) => {
      const r = await get({ id: formData.id });
      if (r.data.success) {
        formData.value = {
          id: r.data.data.id,
        };
      }
      $("#deleteOfferModal").modal('toggle');
    };

    

    onMounted(async () => {
      fetchFormData();
      const r = await getOfferCategories();
      if (r.data.success) {
        categories.value = r.data.data;
      }

    });

    return {
      formData, submitCreateOffer, submitDeleteOffer, createNewOffer, handleFileUpload, edit, del, categories
    };
  },
  watch: {
    'formData.descr'(newDescr) {
        const dynamicTitle = `${newDescr} - Marketplace`;
        useHead({
            title: dynamicTitle,
            meta: [
                { name: 'description', content: newDescr },
                { property: 'og:title', content: newDescr },
                { property: 'og:description', content: newDescr }
            ]
        });
    }
  },
};
</script>

<style scoped>
  .underlined {
    border-bottom: var(--primary-color) 2px solid;
  }
  .offerImg{
    max-width: 100%;
    object-fit: cover;
    height: -webkit-fill-available;
    max-height: 420px;
  }
</style>