// store.js
import { createStore } from 'vuex';
import { fetchUserInfo } from './services/user'; // Adjust this import as needed

const store = createStore({
  state: {
    userInfo: null,
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
  },
  actions: {
    async fetchUserData({ commit }) {
      try {
        const response = await fetchUserInfo();
        commit('setUserInfo', response.data?.info || null);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    },
  },
  getters: {
    getUserPermission: (state) => (entityTitle) => {
      return state.userInfo?.permissions?.find(
        (permission) => permission.entityTitle === entityTitle
      );
    },
    getSuperUserPermission: (state) => {
      return state.userInfo?.superUser || null;
    },
    getUserId: (state) => {
      return state.userInfo?.id || null;
    },
  },
});

export default store;
