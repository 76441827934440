import apiClient from './api';

async function fetchAll(data) {
  try {
    const response = await apiClient.get('/getpartneroffers', data || {}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function getOffersPerCategory(data) {
  try {
    const response = await apiClient.get('/get-offers-per-category', {params: data || {}}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function getOfferCategories(data) {
    try {
      const response = await apiClient.get('/getoffercategories', data || {}); // Replace with your endpoint
      return response;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return error.response;
      } else if (error.request) {
        // The request was made but no response was received
        return { success: false, message: "No response received", details: error.request };
      } else {
        // Something happened in setting up the request that triggered an Error
        return { success: false, message: "Request setup error", details: error.message };
      }
    }
  }

  async function createOrUpdateOffer(data) {
    try {
        const config = {
            headers: {
                'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json',
            }
        };

        const response = await apiClient.post('/createorupdateoffer', data, config);
        return response;
    } catch (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return { success: false, message: "No response received", details: error.request };
        } else {
            return { success: false, message: "Request setup error", details: error.message };
        }
    }
}


async function deleteOffer(offer_id) {
  try {
      // Make a DELETE request with the offer_id in the URL
      const response = await apiClient.post(`/deleteOffer/${offer_id}`);
      return response;
  } catch (error) {
      if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          return error.response;
      } else if (error.request) {
          // The request was made but no response was received
          return { success: false, message: "No response received", details: error.request };
      } else {
          // Something happened in setting up the request that triggered an error
          return { success: false, message: "Request setup error", details: error.message };
      }
  }
}

async function getCurrentOfferData({ id }) {
  try {
    const response = await apiClient.get(`/get-offer-profile/${id}`); // Pass the ID in the URL
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return { success: false, message: "No response received", details: error.request };
    } else {
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}



async function get(data) {
  try {
    const response = await apiClient.get('/getofferdata', {params: data || {}}); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

export {fetchAll, get, createOrUpdateOffer, getOfferCategories, deleteOffer, getCurrentOfferData, getOffersPerCategory}
