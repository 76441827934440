<!-- src/components/Footer.vue -->
<template>
    <footer>
      <span>&copy; 2024 Marketplace | Powered by <a href="https://citrine.gr/" target="_blank"> Citrine</a>. All rights reserved.</span>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'MainFooter'
  }
  </script>
  
  <style scoped>
  footer {
    background-color: #f0f0f0;
    padding: 5px;
    text-align: center;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    font-size: 13px;
    position:fixed;
  }
  a:hover{
    color: #2776A0;
  }
  </style>