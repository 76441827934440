<template>
    <div class="container-fluid px-4 px-xl-5 mt-3 mt-xl-0 mb-5">
      <!-- Breadcrumbs -->
      <nav class="mt-3" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item ">
            <router-link class="color-600" to="/">Αρχική</router-link>
          </li>
          <li class="breadcrumb-item active text-dark" aria-current="page"><b>{{ categoryTitle }}</b></li>
        </ol>
      </nav>

      <div class="row d-flex align-items-center text-end mb-4">
          <div class="col-12 col-xl-10">
              <hr>
          </div>
          <div class="col-12 col-xl-2">
              <h3 class="color-700 fs-3" style="letter-spacing: 2px;">{{ categoryTitle }}</h3>
          </div>
      </div>
  
      <!-- Business Cards Grid -->
      <div class="row row-cols-1 row-cols-md-3 g-4">
        <div class="col" v-for="business in businesses" :key="business.id">
          <router-link :to="`/profile/${categoryId}/${business.id}`" class="card-link">
            <div class="card h-100">
              <div class="image-container">
                <img 
                    :src="business.top_banner_img ? `/storage/${business.top_banner_img}` : require('@/assets/images/marketplace-logo.jpg')" 
                    class="card-img-top" 
                    :alt="business.brandname" 
                  />                <!-- Rounded Badge in the bottom-right corner -->
                <div class="rounded-badge">
                  <span>{{ business.brandname }}</span>
                </div>
              </div>
              <div class="card-body">
                <h5 class="card-title">{{ business.brandname }}</h5>
                <div class="d-flex flex-column-mobile align-items-baseline-mobile justify-content-between align-items-center">
                  <div>
                    <div class="card-text mb-3 mb-xl-0">
                      <div class="d-flex align-items-center">
                        <div>
                          <img src="../assets/images/map.png" alt="" width="30">
                        </div>
                        <div>
                          {{ business.Address }}
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <div>
                          <img src="../assets/images/Phone.png" alt="" width="30">
                        </div>
                        <div>
                          {{ business.Phone }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="logo-container" v-if="business.Logo">
                    <img 
                      :src="business.Logo ? `/storage/${business.Logo}` : require('@/assets/images/marketplace-logo.jpg')" 
                      alt="Logo" 
                      class="logo" 
                    />                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { getBusinessPartnersPerCategoryId } from '@/services/business-partners';
  import { useHead } from '@vueuse/head';
  import { getBusinessCategory } from '@/services/category';

  export default {
    name: 'CategoryPage',
    data() {
      return {
        categoryTitle: '', // Store the category title
        businesses: [] // Store the business data (offers)
      };
    },
    computed: {
      categoryId() {
        return this.$route.params.id; // Get the category ID from the URL
      }
    },
    methods: {
  
      // Fetch businesses per category
      async fetchCategoryBusinesses() {
        try {
          const response = await getBusinessPartnersPerCategoryId({ business_category_id: this.categoryId });
          this.businesses = response.data.success ? response.data.data : [];
        } catch (error) {
          console.error('Error fetching offers data', error);
          this.$toast.error('Error fetching offers data');
        }
      }
    },
    async mounted() {
      // Set the category title based on the category ID from the URL
      if (this.categoryId) {
        const c = await getBusinessCategory(this.categoryId);
        if (c.data.success) {
            this.categoryTitle = c.data.data.title;
        }
      }
      // Fetch the business data (offers) from the API
      this.fetchCategoryBusinesses();
  
      useHead({
        title: `Κατηγορίες - ${this.categoryTitle}`,
        meta: [
          { name: 'description', content: `Κατηγορίες - ${this.categoryTitle}` },
          { property: 'og:title', content: `Κατηγορίες - ${this.categoryTitle}` },
          { property: 'og:description', content: `Κατηγορίες - ${this.categoryTitle}` }
        ]
      });
    }
  };
  </script>
  
  <style scoped>
  a:hover{
    transition: 1s;
    color: #A27B52;
  }
  .breadcrumb {
    background-color: transparent;
    padding: 0;
  }
  
  .title-with-line {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
  }
  
  .category-title {
    flex-shrink: 0;
    padding-left: 20px; /* Space between the line and the title */
    font-size: 2rem;
  }
  
  .title-with-line::before {
    content: '';
    flex-grow: 1;
    height: 2px;
    background-color: #000; /* Line color */
  }
  
  .image-container {
    position: relative;
    height: 250px; /* Fixed height for consistency */
    overflow: hidden;
  }
  
  .card-img-top {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
    border: 1px solid #eee;
  }
  
  /* Rounded Badge */
  .rounded-badge {
    position: absolute;
    bottom: 1px;
    right: 40px;
    background-color: #A27B52;
    padding: 2px 8px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 1rem;
    color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .card-body {
    text-align: left;
  }
  
  .card-title {
    font-size: 1.50rem;
    font-weight: bold;
    padding-left: 5px;
  }
  
  .card-text {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }
  
  .logo {
    max-height: 50px;
    height: 40px
  }
  
  .badge {
    font-size: 0.85rem;
    padding: 0.5em 1em;
    border-radius: 5px;
  }
  
  .d-flex {
    display: flex;
  }
  
  .justify-content-between {
    justify-content: space-between;
  }
  
  .align-items-center {
    align-items: center;
  }
  
  .card {
    cursor: pointer;
    box-shadow: 3px 3px 10px 5px #eee;
    padding: 15px;
    border-radius: 30px;
  }

  @media only screen and (max-width: 768px){
    .card-title{
      font-size: 1.25rem;
    }
    .flex-column-mobile{
      flex-direction: column;
    }
    .align-items-baseline-mobile{
      align-items: baseline !important;
    }
    .breadcrumb{
      justify-content: center;
      margin-bottom: 0;
    }
    .logo-container{
      margin: 0 auto;
    }
  }
  

  </style>
  