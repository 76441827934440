<template>
  <div class="login-wrapper d-flex justify-content-center w-100 h-100">
    <!-- Left Section: Logo and App Title -->
    <div class="col-xl-6 p-3 mb-3 mb-xl-0 col-12 d-flex flex-column justify-content-center align-items-center text-center" style="background: #ECECEC;">
      <div v-if="appInfo && appInfo.image_file_name" class="mb-3">
        <img :src="`/storage/LOGOS/${appInfo.image_file_name}`" alt="App Logo" class="Loginlogo"/>
      </div>
      <h1 class="fw-bold color-800 loginTitle">MARKETPLACE</h1>
      <h2 class="text-uppercase color-800" v-if="appInfo">{{ appInfo.title }}</h2>
    </div>
    <!-- Right Section: Login Form -->
    <div class="col-xl-6 col-12 d-flex flex-column justify-content-center align-items-center mb-5 mb-xl-0">
      <div class="form-container">
        <h3 class="fw-bold color-800 fs-1 text-center loginTitle">ΚΑΛΩΣ </h3>
        <h3 class="fw-bold color-800 fs-1 text-center loginTitle"> ΗΡΘΑΤΕ</h3>
        <small class="mb-3 d-block text-center">Αν έχετε κάνει εγγραφή κ έχει γίνει αποδοχή της αίτησής σας, μπορείτε να συνδεθείτε στο λογαριασμό σας.</small>
        <form class="row g-1 p-3 p-md-2" @submit.prevent="login" @keydown.enter.prevent="login" method="POST" action="/backend/login">
          <div class="col-12">
            <div class="mb-2">
              <label class="form-label color-600">Διεύθυνση Email</label>
              <input type="email" class="form-control form-control-md login-email" placeholder="name@example.com" name="username" v-model="username">
            </div>
          </div>
          <div class="col-12">
            <div class="mb-2">
              <div class="form-label">
                <span class="d-flex color-600 justify-content-between align-items-center">
                  Κωδικός πρόσβασης
                </span>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <input id="showPassword" type="password" v-model="password" class="form-control form-control-md login-password" placeholder="********">
                <span class="showPasswordIcon" @click="showPass()"><i class="icofont-eye-alt"></i></span>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="form-label">
                <span class="d-flex justify-content-between align-items-center">
                  <!-- <label class="fw-light form-check-label" for="flexCheckDefault">
                Να με θυμάσαι
              </label> -->
                  <a class="text-primary" @click.prevent="goToResetPassword()" href="#">Ξέχασα τον κωδικό μου</a>
                </span>
              </div>
          </div>

          <div class="col-12 text-center mt-4">
            <button class="fw-bold btn px-4 py-2 text-uppercase btn-login" alt="signin" @click="login">ΣΥΝΔΕΣΗ</button>
          </div>
          <button @click="goToRegisterBusinessPartner" class="btn btn-link mt-3" style="color: grey">Εγγραφή στο marketplace</button>

        </form>

        <div class="error-div" v-if="errorMessage">
          <p>{{ errorMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router'; // Importing useRouter
import { login as authLogin } from '../services/auth.js';
import { getAppInfo } from '@/services/appInfo.js';

export default {
  name: 'Login',
  setup() {
    const router = useRouter(); // Using useRouter hook
    const username = ref('');
    const password = ref('');
    const rememberMe = ref(0);
    const errorMessage = ref(null);
    const appInfo = ref(null);

    const handleKeyPressed = (event) => {
      if (event.key == 'Enter') {
        document.querySelector('.btn-login').click();
      }
    };

    onMounted(async () => {
      try {
        const info = await getAppInfo();
        appInfo.value = info.data.success ? info.data.data : false;
      } catch (e) {
        errorMessage.value = 'Failed to load app info';
      }
      document.addEventListener('keyup', handleKeyPressed);
    });

    onUnmounted(() => {
      document.removeEventListener('keyup', handleKeyPressed);
    });

    const login = async () => {
      errorMessage.value = null;
      if (username.value && password.value) {
        try {
          const response = await authLogin(username.value, password.value);
          if (response.data?.success) {
            if (response.data?.data?.token?.plainTextToken) {
              localStorage.setItem('api_token', response.data?.data?.token?.plainTextToken);
            }
            if (response.data?.data?.info?.superUser) {
              router.push('/business-partners'); // Using router.push instead of this.$router.push
            } else {
              router.push('/offers');
            }
          } else {
            errorMessage.value = "Λάθος στοιχεία σύνδεσης";
          }
        } catch (e) {
          errorMessage.value = "Λάθος στοιχεία σύνδεσης";
        }
      }
    };

    const showPass = () => {
      const passField = document.getElementById('showPassword');
      passField.type = passField.type === 'password' ? 'text' : 'password';
    };

    const goToResetPassword = () => {
      router.push('/reset-password'); // Using router.push
    };

    const goToRegisterBusinessPartner = () => {
      router.push('/business-partner-request'); // Using router.push
    };

    return {
      username,
      password,
      rememberMe,
      errorMessage,
      appInfo,
      login,
      showPass,
      goToResetPassword,
      goToRegisterBusinessPartner
    };
  }
};
</script>

<style scoped>
/* Main Wrapper to hold the left and right sections */
.login-wrapper {
  display: flex;
  justify-content: space-between;
  height: 100vh !important;
}
.loginTitle{
  text-shadow: 2px 0px;
}
.Loginlogo{
  height: 200px;
}
/* Left Section: App info, occupies half the screen */
.left-section {
  background-color: rgb(240, 240, 240);
  width: 50%; /* Takes up half the screen */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);

}

/* Right Section: Login form, occupies the other half */
.right-section {
  background-color: white;
  width: 50%; /* Takes up half the screen */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);

}


/* The form container */
.form-container {
  width: 100%;
  max-width: 400px;
}

/* Input fields styling */
.login-email,
.login-password {
  width: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  letter-spacing: 2px;
}

/* Show password icon */
.showPasswordIcon {
  color: #8d8d8d;
  font-size: large;
  position: absolute;
  right: 16%;
}

/* Error message styling */
.error-div {
  color: red;
  text-align: center;
}

/* Adjust the button style */
.btn-login {
  background-color: #428AB7;
  color: #fff;
  padding: 10px 30px;
  font-size: 1.25rem;
}
.btn-login:hover{
  color:#ffffff;
  background-color: #888888;
}

/* Responsive behavior for smaller screens */
@media (max-width: 768px) {
  .login-wrapper {
    flex-direction: column;
    height: unset !important;
    justify-content: start !important;
  }
  .Loginlogo{
    height: 160px;
  }
  .left-section,
  .right-section {
    width: 100%; /* Stack the sections vertically on smaller screens */
  }
  .btn-login {
    padding: 5px 20px;
  }
}
</style>

