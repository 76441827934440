<template>
  <component :is="layout">
    <router-view />
  </component>
  <div v-if="isLoading" class="loading-spinner d-flex justify-content-center align-items-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <MainFooter/>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import LoginLayout from './layouts/LoginLayout.vue';
import MainLayout from './layouts/MainLayout.vue';
import MainFrontLayout from './layouts/MainFrontLayout.vue';
import RegisterLayout from './layouts/RegisterLayout.vue';
import loadingStore from './loading'; // Make sure this path is correct
import MainFooter from './layouts/MainFooter.vue';

export default {
  name: 'App',
  components: {
    LoginLayout,
    MainLayout,
    RegisterLayout,
    MainFrontLayout,
    MainFooter
  },
  setup() {
    const isLoading = computed(() => loadingStore.isLoading.value);
    const route = useRoute();
    const layout = computed(() => route.meta.layout || 'MainLayout');
    
    return { layout, isLoading };
  }
};
</script>

<style>
  @font-face {
    font-family: 'cera';
    src: url('@/assets/fonts/cera/cera.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  * {
    font-family: 'cera', sans-serif;
  }
  .loading-spinner {
    position: fixed;
    top: 12%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    margin-top: 200px; /* Adjust this value to move the spinner down */
  }

  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
  .swiper-button-prev, .swiper-button-next{
    border: 2px solid #0C4C73 !important;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    color: #0C4C73;
  }
  .swiper-button-prev:hover, .swiper-button-next:hover{
    transform: scale(1.1);
    transition: 1s;
    background: #0C4C73;
    color: #fff;
  }
  .swiper-button-next:after, .swiper-button-prev:after{
    font-size: 20px;
    font-weight: bold;
    text-shadow: -1px 0px #0C4C73;
  }
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    border: 2px solid #0C4C73;
    opacity: 1;
    background: #ffffff
  }
  .swiper-pagination-bullet-active{
    background: #0C4C73;
  }
  .swiper-pagination{
    position: relative;
  }
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
</style>
