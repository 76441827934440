<template>
    <!-- Navbar Section -->
    <nav v-if="!isProfilePage" class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid justify-content-center">
            <RouterLink class="navbar-brand" to="/">
                <div class="d-flex align-items-center gap-3">
                    <div>
                        <img v-if="apiData" :src="`/storage/LOGOS/${apiData.image_file_name}`" alt="Logo">
                    </div>
                    <div>
                        <h1 class="fw-bold fs-3 color-800">Marketplace</h1>
                        <h2 class="mb-0 fs-5 text-dark" v-if="apiData">{{apiData.title}}</h2>
                    </div>
                </div>
                
                
            </RouterLink>
            <form class="d-flex ms-auto w-25 w-smScrn-100 mt-3 mt-xl-0 position-relative" @submit.prevent>
                <!-- Search Input -->
                <input 
                    ref="searchInput"
                    v-model="searchQuery" 
                    @keyup="search"
                    @click="search" 
                    class="form-control search-input" 
                    type="search" 
                    placeholder="Αναζήτηση Επιχείρησης..." 
                    aria-label="Search"
                >
                <img 
                    v-if="!searchQuery" 
                    class="search-icon" 
                    src="../assets/images/search-icon.png" 
                    alt="Search Icon"
                >
                <!-- Search Results Section -->
                <div ref="searchResults" v-if="searchResults.length > 0" class="search-results position-absolute w-100">
                    <div class="card" v-for="business in searchResults" :key="business.id">
                        <!-- Make the entire card clickable by wrapping it in RouterLink -->
                        <RouterLink :to="getLink(business)" class="card-link">
                            <div class="card-body">
                                <span class="card-title fs-6">{{ business.brandname }}</span>
                            </div>
                        </RouterLink>
                    </div>
                </div>
            </form>
        </div>
    </nav>

    <slot/>
</template>

<script>
import { RouterLink } from 'vue-router';
import { getAppInfo } from '@/services/appInfo';
import { searchBusinessPartner } from '@/services/business-partners';

export default {
    name: 'MainFrontLayout',
    data() {
        return {
            apiData: null, // Variable to store the API response
            error: null,   // Variable to handle potential errors
            searchQuery: '', // Bound to the search input
            searchResults: [] // Array to hold search results
        };
    },
    computed: {
        isProfilePage() {
            // Check if the current route path is '/profile/'
            return this.$route.path.indexOf('/profile/') !== -1;
        }
    },
    mounted() {
        // API call is made when the component is mounted
        this.fetchData();
        // Add a click listener to handle click outside
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        // Remove the click listener when the component is destroyed
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        async fetchData() {
            try {
                // Replace with your actual API URL
                const response = await getAppInfo();
                this.apiData = response.data.success ? response.data.data : false;
            } catch (error) {
                this.error = error.message || 'Error fetching data';
                console.error(this.error);
            }
        },
        async search() {
            let self = this;
            setTimeout(async () => {
                // Ensure the search query is at least 2 characters long
                if (self.searchQuery.length < 2) {
                    self.searchResults = [];
                    return;
                }
                try {
                    // Call the search API with the current search query
                    const response = await searchBusinessPartner({ search: self.searchQuery });
                    self.searchResults = response.data.success ? response.data.data : []; // Assuming the response contains an array of businesses
                } catch (error) {
                    console.error('Error searching:', error);
                }
            }, 500);
        },
        resetSearchResults() {
            // Clear search results and search query
            this.searchResults = [];
            this.searchQuery = '';
        },
        getLink(business) {
            if (business.business_category_id && Array.isArray(business.business_category_id)) {
                return '/profile/' + business.business_category_id[0] + '/' + business.id;
            }
            if (business.business_category_id) {
                return '/profile/' + business.business_category_id + '/' + business.id;
            } else {
                return '#';
            }
        },
        handleClickOutside(event) {
            const searchInput = this.$refs.searchInput;
            const searchResults = this.$refs.searchResults;
            
            if (
                searchInput && !searchInput.contains(event.target) &&
                searchResults && !searchResults.contains(event.target)
            ) {
                this.resetSearchResults();
            }
        }
    }
};
</script>

<style scoped>
    .navbar {
        padding: 10px 35px;
    }
    .navbar-brand img {
        height: 110px;
    }
    .position-relative {
        position: relative;
    }
    .search-results {
        background: white;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        z-index: 10;
        max-height: 300px;
        overflow-y: auto;
        margin-top: 41px;
    }
    .card {
        z-index: 99999;
    }
    .card-link {
        display: block;
        text-decoration: none;
        color: inherit; /* Prevent RouterLink from changing the text color */
        z-index: 99999;
    }
    .card-link:hover {
        text-decoration: none;
    }
    .card-body {
        padding: 10px;
    }
    .search-icon{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        width: 30px;
    }
    .search-input{
        border: 0px;
        border-bottom: 2px solid var(--color-300);
        border-radius: 0px;
    }
    @media only screen and (max-width: 768px){
        .w-smScrn-100{
            width: 100% !important;
        }
        .navbar {
            padding: 10px 15px;
        }
    }
</style>
