<template>
    <div class="body d-flex py-lg-3 py-md-2">
      <div class="container-xxl">
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="card border-0 mb-2 no-bg">
              <div class="card-header py-1 px-0 d-sm-flex align-items-center justify-content-between main-button-area">
                <h4 class="flex-fill mb-0 mt-sm-0" style="color:var(--primary-color)">Προφίλ επιχείρησης</h4>
                <!-- Edit button for navigating to the edit page -->
                <RouterLink to="/business-partner-profile">
                  <button class="btn btn-primary">
                    <i class="icofont-edit text-white"></i>
                  </button>
                </RouterLink>
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix g-3">
          <div class="col-lg-12">
            <!-- Presentation of values -->
            <div class="row">
              <div class="col-md-6">
                <label class="form-label"><strong>Επωνυμία:</strong></label>
                <p>{{ formData.brandname }}</p>
              </div>
              <div class="col-md-6">
                <label class="form-label"><strong>Νομική Επωνυμία:</strong></label>
                <p>{{ formData.trademark }}</p>
              </div>
            </div>
  
            <div class="row">
              <div class="col-md-6">
                <label class="form-label"><strong>ΑΦΜ:</strong></label>
                <p>{{ formData.Vatcode }}</p>
              </div>
              <div class="col-md-6">
                <label class="form-label"><strong>Logo:</strong></label>
                <div class="d-flex">
                  <img v-if="formData.Logo" :src="`/storage/${formData.Logo}`" width="38" height="38" />
                </div>
              </div>
            </div>
  
            <div class="row">
              <div class="col-md-6">
                <label class="form-label"><strong>Περιγραφή επιχείρησης:</strong></label>
                <p>{{ formData.business_descr }}</p>
              </div>
              <div class="col-md-6">
                <label class="form-label"><strong>Ωράριο λειτουργίας:</strong></label>
                <p>{{ formData.open_hours }}</p>
              </div>
            </div>
  
            <div class="row">
              <div class="col-md-6">
                <label class="form-label"><strong>Διεύθυνση:</strong></label>
                <p>{{ formData.Address }}</p>
              </div>
              <div class="col-md-6">
                <label class="form-label"><strong>ΤΚ:</strong></label>
                <p>{{ formData.Zipcode }}</p>
              </div>
            </div>
  
            <div class="row">
              <div class="col-md-6">
                <label class="form-label"><strong>Επικοινωνία:</strong></label>
                <p>{{ formData.Contactperson }}</p>
              </div>
              <div class="col-md-6">
                <label class="form-label"><strong>Email:</strong></label>
                <p>{{ formData.Email }}</p>
              </div>
            </div>
  
            <div class="row">
              <div class="col-md-6">
                <label class="form-label"><strong>Τηλ:</strong></label>
                <p>{{ formData.Phone }}</p>
              </div>
              <div class="col-md-6">
                <label class="form-label"><strong>Κινητό:</strong></label>
                <p>{{ formData.mobile }}</p>
              </div>
            </div>
  
            <div class="row">
              <div class="col-md-6">
                <label class="form-label"><strong>Latitude:</strong></label>
                <p>{{ formData.Lat }}</p>
              </div>
              <div class="col-md-6">
                <label class="form-label"><strong>Longitude:</strong></label>
                <p>{{ formData.Lon }}</p>
              </div>
            </div>
  
            <!-- Social Links Section at the End -->
            <div class="row">
              <div class="col-md-4">
                <label class="form-label"><strong>Web:</strong></label>
                <p>{{ formData.Webpage_link }}</p>
              </div>
              <div class="col-md-4">
                <label class="form-label"><strong>Facebook:</strong></label>
                <p>{{ formData.Facebook_link }}</p>
              </div>
              <div class="col-md-4">
                <label class="form-label"><strong>Instagram:</strong></label>
                <p>{{ formData.instagram_link }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { useToast } from 'vue-toastification';
  import { getCurrentBusinessPartnerData } from '../services/business-partners';
  
  export default {
    name: 'ShowBusinessPartnerProfile',
    setup() {
      const formData = ref({
        brandname: '',
        trademark: '',
        Vatcode: null,
        Logo: null,
        business_descr: '',
        open_hours: '',
        Address: '',
        Zipcode: '',
        Contactperson: '',
        Email: '',
        Phone: '',
        mobile: '',
        Webpage_link: '',
        Facebook_link: '',
        instagram_link: '',
        Lat: '',
        Lon: '',
      });
  
      const toast = useToast();
  
      const fetchFormData = async () => {
        const data = await getCurrentBusinessPartnerData();
        if (data.data.success) {
          formData.value = { ...formData.value, ...data.data.data };
        }
      };
  
      onMounted(() => {
        fetchFormData();
      });
  
      return {
        formData,
      };
    },
  };
  </script>
  
  <style scoped>
  /* Custom styles */
  </style>
  